<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title><span v-if="!isSmall">GreatScots </span>List Editor</v-toolbar-title>
      <v-spacer></v-spacer>
      <list-search :isSmall="isSmall" @selected="selected"></list-search>
      <new-list :isSmall="isSmall" @created="selected"></new-list>
    </v-toolbar>
    <v-toolbar>
      <v-toolbar-title style="font-size:.9em">Editing: {{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon color="grey">fal fa-user-plus</v-icon>
          </v-btn>
        </template>
        <span>List Sharing is coming soon</span>
      </v-tooltip>
    </v-toolbar>
    <v-expansion-panels v-model="activeGroup">
      <v-expansion-panel v-for="(group, index) in groups" :key="'group-' + index">
        <v-expansion-panel-header>{{ group.group }} ({{ group.people.length }} members)</v-expansion-panel-header>
        <v-expansion-panel-content>
          <edit-group :title="group.group" :people="group.people" @save="({ title }) => { group.group = title; save() }" @add="(id) => { group.people.push(id); save() }" @remove="(id) => { remove(id, group.people) }"></edit-group>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-actions>
      <v-btn text @click="addGroup" style="margin-top:1em;">
        <v-icon left>fal fa-plus</v-icon>
        Add Group
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { ref, computed } from '@vue/composition-api'

export default {
  components: {
    NewList: () => import('@/components/greatscots/editor/NewList'),
    ListSearch: () => import('@/components/greatscots/editor/ListSearch'),
    EditGroup: () => import('@/components/greatscots/editor/EditGroup')
  },
  setup (props, { root }) {
    const activeGroup = ref(null)
    const listId = ref('')
    const title = ref('')
    const groups = ref([])
    const isOwner = ref(false)
    const share = ref([])
    const addGroup = () => {
      groups.value.push({ group: 'New Group', permissions: [], people: [] })
    }

    const user = computed(() => {
      const user = root.$store.state.user
      if ('spoof' in user) return user.spoof
      return user
    })

    const isSmall = computed(() => {
      const bp = root.$vuetify.breakpoint.name
      if (bp === 'xs' || bp === 'sm') return true
      return false
    })

    const selected = (id) => {
      listId.value = id
      root.$feathers.service('directory/list').get(id).then((data) => {
        console.log(data)
        title.value = data.title
        isOwner.value = JSON.stringify(data.owner) === JSON.stringify(user.value.directoryId)
        share.value = data.share || []
        groups.value = data.groups || []
      })
    }

    function save () {
      root.$feathers.service('directory/list').patch(listId.value, {
        title: title.value,
        groups: groups.value || []
      })
    }

    function remove (id, group) {
      for (let i = 0; i < group.length; i++) {
        if (group[i] === id) {
          group.splice(i, 1)
          save()
        }
      }
    }

    return {
      activeGroup,
      listId,
      title,
      groups,
      addGroup,
      user,
      isOwner,
      isSmall,
      selected,
      save,
      remove
    }
  }
}
</script>
